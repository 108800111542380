import React from 'react';
import { defineMessages, t } from '@sm/intl';

import SimpleLogoHeader from '~app/components/SimpleLogoHeader';
import SimpleHero from '~app/components/SimpleHero';
import HeroFormCallout from '~app/components/HeroFormCallout';
import FortuneCallout from '~app/components/FortuneCallout';
import ProductsCalloutList from '~app/components/ProductsCalloutList';
import { ProductsCalloutListItem } from '../ProductsCalloutList/ProductsCalloutList';
import BrazilEndpage from '../endpages/BrazilEndpage';
import { BrazilEpProps } from '~helpers/types';

export const COPY = defineMessages({
  HERO_FORM_CALLOUT_BOLD_HEADER: {
    id: 'RebrandEpFormCore.HeroFormCalloutBoldHeader',
    defaultMessage: 'Want to create your own surveys?',
    description:
      "[Type: Header][Vis: high] - The Hero's Primary tagline. A question to put into context the subheader beneath this tagline, and to entice users to fillout and submit the form directly below the header.",
  },
  HERO_FORM_CALLOUT_SUBHEADER: {
    id: 'RebrandEpFormCore.HeroFormCalloutSubHeader',
    defaultMessage:
      'Gather opinions from customers, employees, prospects, and more. Use your insights to make better, data-driven decisions.',
    description:
      "[Type: Header][Vis: high] - The Hero's subheader. A value proposition, giving a clear example of what they can get if they fill out the form below.",
  },
  HERO_FORM_CTA_EMAIL_ADDRESS: {
    id: 'RebrandEpFormCore.HeroFormCTAEmailAddress',
    defaultMessage: 'Email address',
    description: '[Type: Label][Vis: high] - The Label for an email input field.',
  },
  HERO_FORM_CTA_EMAIL_ADDRESS_PLACEHOLDER: {
    id: 'RebrandEpFormCore.HeroFormCTAEmailAddressPlaceholder',
    defaultMessage: 'you@example.com',
    description: '[Type: Label][Vis: high] - The placeholder for an email input field.',
  },
  HERO_FORM_CTA_PASSWORD: {
    id: 'RebrandEpFormCore.HeroFormCTAPassword',
    defaultMessage: 'Password',
    description: '[Type: Label][Vis: high] - The Label for a password input field.',
  },
  HERO_FORM_CTA_PASSWORD_PLACEHOLDER: {
    id: 'RebrandEpFormCore.HeroFormCTAPasswordPlaceholder',
    defaultMessage: '(at least 8 characters)',
    description: '[Type: Label][Vis: high] - The placeholder for a password input field.',
  },
  HERO_FORM_CTA_PRIVACY_CONSENT: {
    id: 'RebrandEpFormCore.HeroFormCTAPrivacyConsent',
    defaultMessage:
      'I agree to the <a href="/mp/legal/terms-of-use/">Terms of Use</a> and understand my data will be handled in accordance with the <a href="/mp/legal/privacy/">Privacy Policy</a>.',
    description:
      '[Type: Label][Vis: high] - The Label for a checkbox consenting to the Terms of Use and Privacy Policy. Only for users located in Germany.',
  },
  HERO_FORM_CTA_NEWSLETTER_OPTOUT: {
    id: 'RebrandEpFormCore.HeroFormCTANewsletterOptOut',
    defaultMessage:
      'I do not want to receive information and offers relevant to SurveyMonkey services via email. You can also opt-out of these emails in your My Account page anytime.',
    description:
      '[Type: Label][Vis: high] - The Label for a checkbox to opt out of Newsletter subscription. Only for users located in Germany.',
  },
  HERO_FORM_CTA_SUBMIT: {
    id: 'RebrandEpFormCore.HeroFormCTASubmit',
    defaultMessage: 'Create free account',
    description:
      '[Type: Button][Vis: high] - The button users are required to click to create an account using the values they entered in the email and password feilds.',
  },
  HERO_FORM_CTA_DISCLAIMER: {
    id: 'RebrandEpFormCore.HeroFormCTADisclaimer',
    defaultMessage:
      'By clicking “Create Free Account” or Apple or Google, you agree to the <a href="/mp/legal/terms-of-use/">Terms of Use</a> and <a href="/mp/legal/privacy/">Privacy Notice</a>. You also agree to receive information and offers relevant to our services via email. You can opt-out of these emails in your My Account page anytime.',
    description:
      '[Type: Button][Vis: high] - A legal disclaimer containing links to legal documents Terms of Service and Privacy Notice',
  },

  FORTUNE_CALLOUT_COPY: {
    id: 'RebrandEpFormCore.FortuneCalloutCopy',
    defaultMessage: '95% of the Fortune 500 rely on SurveyMonkey for People Powered Data',
    description: '[Type: Header][Vis: low] - A promotional statistic designed to show corporate trust and prestige',
  },
  FORTUNE_CALLOUT_CTA: {
    id: 'RebrandEpFormCore.FortuneCalloutCTA',
    defaultMessage: 'Sign up free',
    description: "[Type: Button][Vis: high] - Fortune's CTA which sends people to a sign up page",
  },
});

export type Props = {
  utSource: string;
  utSource2: string;
  calloutItem1: ProductsCalloutListItem;
  calloutItem2: ProductsCalloutListItem;
  calloutBrazil?: BrazilEpProps;
  treatment?: string;
  hideCTAs?: boolean;
};

const RebrandEpFormCore = ({
  utSource,
  utSource2,
  calloutItem1,
  calloutItem2,
  calloutBrazil,
  treatment,
  hideCTAs = false,
}: Props): React.ReactElement => {
  const HERO_FORM_CTA = {
    ctaFormEmail: t(COPY.HERO_FORM_CTA_EMAIL_ADDRESS),
    ctaFormEmailPlaceholder: t(COPY.HERO_FORM_CTA_EMAIL_ADDRESS_PLACEHOLDER),
    ctaFormNewsletterOptOut: t(COPY.HERO_FORM_CTA_NEWSLETTER_OPTOUT),
    ctaFormPassword: t(COPY.HERO_FORM_CTA_PASSWORD),
    ctaFormPasswordPlaceholder: t(COPY.HERO_FORM_CTA_PASSWORD_PLACEHOLDER),
    ctaFormPrivacyConsent: t(COPY.HERO_FORM_CTA_PRIVACY_CONSENT, {}, { html: true }),
    ctaFormSubmit: t(COPY.HERO_FORM_CTA_SUBMIT),
    disclaimer: t(COPY.HERO_FORM_CTA_DISCLAIMER),
    utSource,
    utSource2,
  };

  const boldHeader: string = t(COPY.HERO_FORM_CALLOUT_BOLD_HEADER);
  const subHeader: string = t(COPY.HERO_FORM_CALLOUT_SUBHEADER);

  return (
    <>
      {hideCTAs ? (
        <>
          <SimpleHero utSource={utSource} utSource2={utSource2} />
          <ProductsCalloutList calloutItem1={calloutItem1} calloutItem2={calloutItem2} utSource={utSource} hideCTAs />
          <FortuneCallout
            copy={t(COPY.FORTUNE_CALLOUT_COPY)}
            ctaText={COPY.FORTUNE_CALLOUT_CTA}
            ctaUsage="sign-up tertiary"
            utSource={utSource}
            utSource2={utSource2}
            hideCTAs
          />
        </>
      ) : (
        <>
          {utSource2 === 'RebrandEpFormV1' ? (
            <HeroFormCallout
              utSource={utSource}
              utSource2={utSource2}
              boldHeader={boldHeader}
              subHeader={subHeader}
              treatment={treatment}
              formCopy={HERO_FORM_CTA}
            />
          ) : (
            <>
              <SimpleLogoHeader utSource={utSource} utSource2={utSource2} />
              <HeroFormCallout
                utSource={utSource}
                utSource2={utSource2}
                boldHeader={boldHeader}
                subHeader={subHeader}
                treatment={treatment}
                formCopy={HERO_FORM_CTA}
                calloutBrazil={calloutBrazil}
              />
              {treatment === 'treatment1' && calloutBrazil ? (
                <BrazilEndpage calloutBrazil={calloutBrazil} utSource={utSource} />
              ) : (
                <>
                  <ProductsCalloutList calloutItem1={calloutItem1} calloutItem2={calloutItem2} utSource={utSource} />
                  <FortuneCallout
                    copy={t(COPY.FORTUNE_CALLOUT_COPY)}
                    ctaText={COPY.FORTUNE_CALLOUT_CTA}
                    ctaUsage="sign-up tertiary"
                    utSource={utSource}
                    utSource2={utSource2}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default RebrandEpFormCore;
