import React, { useContext, useMemo } from 'react';
import { generateMetricsAttribute } from '@sm/metrics';
import { StaticContext } from '@sm/webassets/StaticContext';
import { Box } from '@wds/box';
import { Button } from '@wds/button';
import { Typography } from '@wds/typography';
import { getSrc } from '~app/helpers/assetLoading';
import useStyles from './useStyles';
import { BrazilEpProps } from '~helpers/types';

const PlatformModule = ({
  icon,
  iconAlt = 'sparkle',
  heading,
  copy,
  href,
  utSource,
  ctaText,
  ctaUsage,
  name,
  color = 'light',
}: BrazilEpProps): React.ReactElement => {
  const { mainContainer, copy: copyClass, logo: logoClass } = useStyles();
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);

  const metricsData = useMemo(
    () =>
      generateMetricsAttribute({
        data: {
          amplitudeEvent: 'explore the product',
          itemSelected: ctaUsage,
          templateType,
          surveyId,
          collectorId,
          respondentId,
          log: { message: `${utSource}-${name}-${ctaText}` },
        },
      }),
    [ctaUsage, templateType, surveyId, collectorId, respondentId, utSource, name, ctaText]
  );

  return (
    <Box className={mainContainer}>
      <img src={getSrc(icon)} alt={iconAlt} className={logoClass} />
      <Typography component="h3" color={color} variant="sectionTitle" align="center" weight="medium">
        {heading}
      </Typography>
      <Box className={copyClass}>
        <Typography color={color} component="p" variant="body" align="center">
          {copy}
        </Typography>
      </Box>
      <Button variant="solid" href={href} size="lg" data-sm-metrics={metricsData}>
        {ctaText}
      </Button>
    </Box>
  );
};

export default PlatformModule;
