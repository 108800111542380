import { createUseStyles } from 'react-jss';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';
import { getSrc } from '~app/helpers/assetLoading';
import rebrandFocusImgV1 from '~static/images/dareToAsk/slim-screenshots.webp';
import rebrandV2DesktopImg from '~static/images/brazilExp/br-desktop.webp';
import rebrandV2MobileImg from '~static/images/brazilExp/br-mobile.png';

const useStyle = createUseStyles((theme: CmsWrenchTheme) => {
  const mediaQueries = {
    sm: `@media (min-width: ${theme.breakpoints.sm}px)`,
    md: `@media (min-width: ${theme.breakpoints.md}px)`,
    lg: `@media (min-width: ${theme.breakpoints.lg}px)`,
    xl: `@media (min-width: ${theme.breakpoints.xl}px)`,
  };

  const flexCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const container = {
    padding: '20px',
    maxWidth: '1400px',
    margin: '0 auto',
    overflowX: 'hidden',
    alignItems: 'center',
    [mediaQueries.sm]: { padding: '40px 60px 100px' },
    [mediaQueries.lg]: { padding: '80px 0 100px 100px', alignItems: 'flex-start' },
    [mediaQueries.xl]: { padding: '80px 0 100px 165px' },
  };

  return {
    display: { backgroundColor: theme.brandColors.primary.vine },

    wrapperV1: {
      ...container,
      [mediaQueries.sm]: { padding: '40px 60px 220px' },
    },

    imageV1: {
      backgroundImage: `url(${getSrc(rebrandFocusImgV1)})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      position: 'relative',
      top: '200px',
      [mediaQueries.md]: { width: '550px', height: '433px', left: '150px' },
      [mediaQueries.lg]: { width: '620px', height: '504px', left: '112px' },
    },

    ctaFormV1: {
      margin: '0 auto',
      maxWidth: 547,
      borderRadius: [[8, 8, 0, 0]],
      padding: '24px',
      backgroundColor: '#fff',
      [mediaQueries.sm]: { borderRadius: 8, padding: [[48, 64]] },
    },

    wrapper: container,

    imagePlacement: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      position: 'relative',
      top: '70px',
      [mediaQueries.md]: { width: '550px', height: '433px', left: '150px' },
      [mediaQueries.lg]: { width: '620px', height: '504px', left: '240px' },
    },

    image: {
      backgroundImage: `url(${getSrc(rebrandFocusImgV1)})`,
    },

    heroForm: { display: 'flex' },

    imagePlacementBr: {
      ...flexCenter,
      flexDirection: 'column',
      gap: '20px',
      [mediaQueries.md]: { justifyContent: 'space-between' },
      [mediaQueries.lg]: { flexDirection: 'row', gap: '0' },
    },

    imageV2Brazil: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${getSrc(rebrandV2MobileImg)})`,
      width: '285px',
      height: '275px',
      padding: '0 20px',
      [mediaQueries.md]: {
        backgroundImage: `url(${getSrc(rebrandV2DesktopImg)})`,
        width: '540px',
        height: '470px',
        marginLeft: '160px',
      },
      [mediaQueries.lg]: {
        width: '595px',
        height: '504px',
        marginLeft: '200px',
      },
    },

    ctaForm: { margin: '0 auto', maxWidth: '380px' },

    header: {
      '& > section': {
        textAlign: 'center',
        width: '100%',
        maxWidth: '1020px',
        [mediaQueries.md]: { fontSize: '34px' },
        [mediaQueries.lg]: { textAlign: 'left', fontSize: '50px' },
      },
      [mediaQueries.lg]: { width: '100%' },
    },

    subHeader: {
      fontWeight: theme.type.fontWeight.regular,
      width: '100%',
      maxWidth: '1020px',
      '&, & div': { textAlign: 'center' },
      [mediaQueries.lg]: {
        maxWidth: '560px',
        '&, & div': { textAlign: 'left' },
      },
    },
  };
});

export default useStyle;
