import { Box } from '@wds/box';
import React from 'react';
import PlatformModule from './components/PlatformModule';
import BrazilLogoWall from './components/BrazilLogoBanner';
import { BrazilEpProps } from '~helpers/types';

type Props = {
  calloutBrazil: BrazilEpProps;
  utSource: string;
};

const BrazilEndpage = ({ calloutBrazil, utSource }: Props): React.ReactElement => {
  return (
    <Box data-testid="BrazilEndpage">
      <PlatformModule utSource={utSource} {...calloutBrazil} />
      <BrazilLogoWall utSource={utSource} {...calloutBrazil} />
    </Box>
  );
};

export default BrazilEndpage;
