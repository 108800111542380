import React, { useContext } from 'react';
import { defineMessages, t } from '@sm/intl';
import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import { StaticContext } from '@sm/webassets/StaticContext';
import { Button } from '@wds/button';
import { generateMetricsAttribute } from '@sm/metrics';
import { Logo, LogoWithText } from '@sm/webassets/Logos';
import { MobileTabletScreen, DesktopScreen } from '@sm/webassets/MediaComponents';
import { createURL } from '@sm/utils';

import useStyles from './useStyles';

const COPY = defineMessages({
  SIMPLE_HERO_FORM_CALLOUT_BOLD_HEADER: {
    id: 'SimpleHero.SimpleHero.SimpleHeroFormCalloutBoldHeader',
    defaultMessage: 'Get answers with surveys',
    description:
      "[Type: Header][Vis: high] - The Hero's Primary tagline. A brief description of SurveyMonkey's functionality.",
  },
  SIMPLE_HERO_FORM_CALLOUT_CTA: {
    id: 'SimpleHero.SimpleHero.SimpleHeroFormCalloutCTA',
    defaultMessage: 'Create survey',
    description:
      "[Type: Header][Vis: high] - The Hero's only CTA. It takes user's to a login screen or directly to a dashboard to create their survey.",
  },
  LOGO_ARIA_LABEL: {
    id: 'SimpleHero.SimpleHero.LogoAriaLabel',
    defaultMessage: 'SurveyMonkey home',
    description: '[Type: Header][Vis: med] - The SurveyMonkey Logo shown at the top of the page',
  },
});

export type Props = {
  hideCTAs?: boolean;
  utSource: string;
  utSource2: string;
};

const SimpleHero: React.FC<Props> = ({ hideCTAs = true, utSource, utSource2 }) => {
  const utSource3 = 'SimpleHero';
  const { smSimpleHero, logo, smGoldie, smWebRespWebButton, text, heading } = useStyles();
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);
  const amplitudeEventName = 'endpage user interaction';

  return (
    <Box
      data-testid="SimpleHero"
      className={smSimpleHero}
      display="flex"
      flexDirection="column"
      flexAlign="center"
      px={3}
      pt={7}
      pb={8}
    >
      <DesktopScreen>
        <a
          aria-label={t(COPY.LOGO_ARIA_LABEL)}
          data-testid="SimpleHero__Logo"
          href={createURL('/', {
            ut_source: utSource,
            ut_source2: utSource2,
            ut_source3: utSource3,
            ut_ctatext: 'LogoWithText',
          })}
          data-sm-metrics={generateMetricsAttribute({
            data: {
              amplitudeEvent: amplitudeEventName,
              itemSelected: 'logo',
              templateType,
              surveyId,
              collectorId,
              respondentId,
              log: {
                message: `${utSource}-${utSource2}-${utSource3}-LogoWithText`,
              },
            },
          })}
        >
          <LogoWithText color="alt" className={logo} />
        </a>
        <Box mx={1} mt={7} mb={5}>
          <Typography component="h3" color="light" variant="hero1" align="center">
            <span className={heading}>{t(COPY.SIMPLE_HERO_FORM_CALLOUT_BOLD_HEADER)}</span>
          </Typography>
        </Box>
      </DesktopScreen>
      <MobileTabletScreen>
        <a
          aria-label={t(COPY.LOGO_ARIA_LABEL)}
          data-testid="SimpleHero__Goldie"
          href={createURL('/', {
            ut_source: utSource,
            ut_source2: utSource2,
            ut_source3: utSource3,
            ut_ctatext: 'Logo',
          })}
          className={smGoldie}
          data-sm-metrics={generateMetricsAttribute({
            data: {
              amplitudeEvent: amplitudeEventName,
              itemSelected: 'logo',
              templateType,
              surveyId,
              collectorId,
              respondentId,
              log: {
                message: `${utSource}-${utSource2}-${utSource3}-Goldie-cta`,
              },
            },
          })}
        >
          <Logo color="alt" className={text} />
        </a>
        <Box mx={1} mt={7} mb={5}>
          <Typography component="h3" color="light" variant="pageTitle" align="center">
            <span className={heading}>{t(COPY.SIMPLE_HERO_FORM_CALLOUT_BOLD_HEADER)}</span>
          </Typography>
        </Box>
      </MobileTabletScreen>
      {!hideCTAs && (
        <Box className={smWebRespWebButton}>
          <Button
            size="lg"
            color="upgrade"
            href={createURL('/create/start/', {
              ut_source: utSource,
              ut_source2: utSource2,
              ut_source3: utSource3,
              ut_ctatext: COPY.SIMPLE_HERO_FORM_CALLOUT_CTA.defaultMessage,
            })}
            data-sm-metrics={generateMetricsAttribute({
              data: {
                amplitudeEvent: amplitudeEventName,
                itemSelected: 'sign-up primary',
                templateType,
                surveyId,
                collectorId,
                respondentId,
                log: {
                  message: `${utSource}-${utSource2}-${utSource3}-${COPY.SIMPLE_HERO_FORM_CALLOUT_CTA.defaultMessage}`,
                },
              },
            })}
          >
            {t(COPY.SIMPLE_HERO_FORM_CALLOUT_CTA)}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SimpleHero;
