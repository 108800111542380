import React, { useContext, useMemo } from 'react';
import { Box } from '@wds/box';
import { Grid } from '@wds/grid';
import { Typography } from '@wds/typography';
import { Button } from '@wds/button';
import { generateMetricsAttribute } from '@sm/metrics';
import { StaticContext } from '@sm/webassets/StaticContext';
import { getSrc } from '~app/helpers/assetLoading';
import { hyundai, ra, uol, tim, merlin, olx } from '~static/images/brazilExp/icons';
import useStyles from './useStyles';
import { BrazilEpProps } from '~helpers/types';

export const LOGO_COPY = {
  HYUNDAI_LABEL: 'Logotipo da Hyundai',
  RA_LABEL: 'Logotipo da Ra',
  UOL_LABEL: 'Logotipo da Uol',
  TIM_LABEL: 'Logotipo da Tim',
  MERLIN_LABEL: 'Logotipo da Merlin',
  OLX_LABEL: 'Logotipo da Olx',
};

const logosData = [
  { src: hyundai, label: LOGO_COPY.HYUNDAI_LABEL },
  { src: ra, label: LOGO_COPY.RA_LABEL },
  { src: uol, label: LOGO_COPY.UOL_LABEL },
  { src: tim, label: LOGO_COPY.TIM_LABEL },
  { src: merlin, label: LOGO_COPY.MERLIN_LABEL },
  { src: olx, label: LOGO_COPY.OLX_LABEL },
];

const BrazilLogoWall = ({
  utSource,
  ctaUsage,
  brandHeading,
  ctaSignupText,
  signupHref,
  name,
}: BrazilEpProps): React.ReactElement => {
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);
  const { logoContainer, brandBox } = useStyles();

  const logos = useMemo(
    () =>
      logosData.map(({ src, label }) => ({
        src: getSrc(src),
        alt: label,
      })),
    []
  );

  const metricsData = useMemo(
    () =>
      generateMetricsAttribute({
        data: {
          amplitudeEvent: 'endpage user interaction',
          itemSelected: ctaUsage,
          templateType,
          surveyId,
          collectorId,
          respondentId,
          log: { message: `${utSource}-${name}-${ctaSignupText}` },
        },
      }),
    [ctaUsage, templateType, surveyId, collectorId, respondentId, utSource, name, ctaSignupText]
  );

  return (
    <Box className={logoContainer}>
      <Typography component="h3" variant="sectionTitleSm" weight="medium">
        {brandHeading}
      </Typography>
      <Box className={brandBox}>
        <Grid noBleeds noGutters>
          {logos.map(({ src, alt }) => (
            <Grid.Item key={alt} xs={6} sm={4} md={4} lg={2} xl={2}>
              <img src={src} alt={alt} />
            </Grid.Item>
          ))}
        </Grid>
      </Box>
      <Button variant="solid" href={signupHref} size="lg" data-sm-metrics={metricsData}>
        {ctaSignupText}
      </Button>
    </Box>
  );
};

export default BrazilLogoWall;
