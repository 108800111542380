import React, { useMemo } from 'react';
import { t, defineMessages } from '@sm/intl';
import { Box } from '@wds/box';
import { Grid } from '@wds/grid';
import useStyles from './useStyles';
import { getSrc } from '~app/helpers/assetLoading';
import { google, ibm, chime, ebay, mailchimp, cvsHealth } from '~static/images/icons';

export type ColorProp = 'sabaeus' | 'canvas';

type Props = {
  darkBackground?: boolean;
};

const copyDescription = '[Type: label][Vis: low] - The aria label for a corporate logo';
export const SPLIT_HERO_COPY = defineMessages({
  GOOGLE_LABEL: {
    id: 'LogoWall.GoogleLogo',
    defaultMessage: 'Google logo',
    description: copyDescription,
  },
  IBM_LABEL: {
    id: 'LogoWall.IBMLogo',
    defaultMessage: 'IBM logo',
    description: copyDescription,
  },
  EBAY_LABEL: {
    id: 'LogoWall.EbayLogo',
    defaultMessage: 'Ebay logo',
    description: copyDescription,
  },
  MAILCHIMP_LABEL: {
    id: 'LogoWall.MailchimpLogo',
    defaultMessage: 'Mailchimp Logo',
    description: copyDescription,
  },
  CVS_LABEL: {
    id: 'LogoWall.CVSLogo',
    defaultMessage: 'CVS logo',
    description: copyDescription,
  },
  CHIME_LABEL: {
    id: 'LogoWall.ChimeLogo',
    defaultMessage: 'Chime logo',
    description: copyDescription,
  },
});

const LogoWall = ({ darkBackground = false }: Props): React.ReactElement => {
  const { logo } = useStyles({ darkBackground });

  const logos = useMemo(
    () => [
      { src: getSrc(google), alt: t(SPLIT_HERO_COPY.GOOGLE_LABEL) },
      { src: getSrc(ibm), alt: t(SPLIT_HERO_COPY.IBM_LABEL) },
      { src: getSrc(ebay), alt: t(SPLIT_HERO_COPY.EBAY_LABEL) },
      { src: getSrc(mailchimp), alt: t(SPLIT_HERO_COPY.MAILCHIMP_LABEL) },
      { src: getSrc(cvsHealth), alt: t(SPLIT_HERO_COPY.CVS_LABEL) },
      { src: getSrc(chime), alt: t(SPLIT_HERO_COPY.CHIME_LABEL) },
    ],
    []
  );

  return (
    <Box>
      <Grid noBleeds noGutters>
        {logos.map(({ src, alt }) => (
          <Grid.Item xs={6} sm={4} md={4} lg={2} xl={2}>
            <img src={src} alt={alt} className={logo} />
          </Grid.Item>
        ))}
      </Grid>
    </Box>
  );
};

export default LogoWall;
