import { createUseStyles } from 'react-jss';

import { CmsWrenchTheme } from '~app/styles/cmsTheme';

const commonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '20px',
};

const useStyles = createUseStyles((theme: CmsWrenchTheme) => {
  const mediaMinMD = `@media (min-width: ${theme.breakpoints.md}px)`;
  return {
    mainContainer: {
      ...commonStyles,
      textAlign: 'center',
      backgroundColor: '#224F3C',
      height: '495px',

      '& h3': {
        textAlign: 'center',
        fontSize: theme.type.fontSize.sectionTitleSm,
        [mediaMinMD]: {
          fontSize: theme.type.fontSize.pageTitle,
        },
      },
      [mediaMinMD]: {
        height: '400px',
      },
    },
    logoContainer: {
      ...commonStyles,
      height: '475px',
      textAlign: 'center',
      backgroundColor: '#F7F7F7',
      '& h3': {
        textAlign: 'center',
      },
      [mediaMinMD]: {
        height: '350px',
        marginTop: '0',
      },
    },
    brandBox: {
      height: '45%',
      [mediaMinMD]: {
        width: '65%',
        height: '120px',
      },
    },
    copy: {
      width: '100%',
      padding: '0 20px',
      marginBottom: '20px',
      '& p': {
        fontSize: theme.type.fontSize.bodySm,
      },
      [mediaMinMD]: {
        maxWidth: '40%',
        '& p': {
          fontSize: theme.type.fontSize.body,
        },
      },
    },
    logo: {
      height: '70px',
      width: '100px',
      objectFit: 'contain',
    },
  };
});

export default useStyles;
